import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Leftcol from "../components/leftcol"
import Maincol from "../components/maincol"
import Caption from "../components/caption"
import SEO from "../components/seo"

const Recipient = props => (
  <div>
    <p>
      <span className="text-bold">{props.date}</span> {props.name}
    </p>
  </div>
)

export default ({ data }) => (
  <Layout>
    <Leftcol>
      <div className="mb-4 sm:mb-8">
        <Img fluid={data.sch1.childImageSharp.fluid} />
        <Caption text="Founder Erica Tanamachi and Kwok Siong Teh, SFSU Director of Engineering at a Donor Recognition Ceremony" />
      </div>
      <div className="mb-4 sm:mb-8">
      <Img fluid={data.sch2.childImageSharp.fluid} />
      <Caption text="Founder Erica Tanamachi with Jade and Ruby Tanamachi, Peter's Grandchildren at SFSU Donor Recognition" />
      </div>
      <Img fluid={data.bk.childImageSharp.fluid} />
      <Caption text="Erica Tanamachi with Benjamin Kean, 2015 SFSU recipient" />
    </Leftcol>
    <Maincol>
    <SEO title="Scholarships" />
      <h1>Scholarships</h1>
      <p>
        The Peter John Filanc Foundation endowed two scholarships at California
        State Universities. Students can apply directly to the universities
        using the links below.
      </p>
      <p>
        We would like to congratulate all of the student recipients of The Peter
        John Filanc Foundation Scholarships. May you continue to lead with
        dedication, vision and respect as Peter did throughout his career.
      </p>
      <h3 className="mt-8">San Francisco State University</h3>
      <p>
        <a
          href="https://sfsu.academicworks.com/opportunities/8242"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Peter John Filanc Leadership Scholarship in Engineering
        </a>
      </p>

      <h3 className="mt-8">San Diego State University</h3>
      <p><a
        href="https://www.engineering.sdsu.edu/giving/endowments.aspx"
        target="_blank"
        rel="noopener noreferrer"
      >
        The Peter John Filanc Foundation Scholarship in Construction Engineering
      </a></p>

      <p className="font-semibold mt-6">SDSU Recipients</p>
      <Recipient date="2023-2024" name="Sara Lopez" />
      <Recipient date="2022-2023" name="Juliet Luevanos" />
      <Recipient date="2022-2023" name="Bryan Quezada" />
      <Recipient date="2020-2021" name="Devin Knox" />
      <Recipient date="2020-2021" name="Evan Rossi" />
      <Recipient date="2020-2021" name="Rene Osuna" />
      <Recipient date="2019-2020" name="Carson Harp" />
      <Recipient date="2017-2018" name="Kyle Butler" />
      <Recipient date="2017-2018" name="Brianna Billings" />
      <Recipient date="2016-2017" name="Brianna Billings" />
      <Recipient date="2015-2016" name="Alexa Leticia Rizeq" />
      <Recipient date="2015-2016" name="Scott Heaviside" />
      <Recipient date="2015-2016" name="Jacob Hampson" />
      <Recipient date="2015-2016" name="Peter Dowling" />
      <Recipient date="2014-2015" name="Peter Lee" />
      <Recipient date="2014-2015" name="Kenneth Gervacio" />
      <Recipient date="2014-2015" name="Elijah Elliott" />
      <Recipient date="2013-2014" name="David Willis" />
      <Recipient date="2013-2014" name="Keith Walsh" />
      <Recipient date="2012-2013" name="Franz Fischer" />
      <p>2010-2011: UCSD Gordon Engineering Leadership Award $2,500</p>
      <p className="text-sm italic">* Not all recipients listed</p>
    </Maincol>
  </Layout>
)

export const query = graphql`
  query {
    sch1: file(relativePath: { eq: "images/scholarship1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sch2: file(relativePath: { eq: "images/scholarship2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bk: file(relativePath: { eq: "images/benjamin-kean.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 448, maxHeight: 598)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
